import { useReducer, useState } from "react";

function usePagination() {
  // const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentPage, setCurrentPage] = useReducer((x, a: number) => a + 0, 1);

  function goNext() {
    setCurrentPage(currentPage + 1);
  }

  function goPrevious() {
    if(currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  }

  return {
    currentPage,
    goNext,
    goPrevious, 
    setPage: setCurrentPage
  }
}

export default usePagination;